<template>
    <v-row justify="center">
      <v-dialog
          v-model="showDialog"
          persistent
          max-width="1000px"
      >
        <v-card v-if="this.user">
          <v-card-title class="headline">
            {{ $t('Statistics')+": "+ (this.user ? this.user.id : "") }}
          </v-card-title>
          <v-row class="s3m-padding">
            <v-col>
              {{ this.formatLocaleDate(this.user.actualFromDate) + " - " + this.formatLocaleDate(this.user.actualToDate) }}<br>
              {{ $t('text-assing-stats-date-future') }}
            </v-col>
            <v-spacer/>
            <v-col class="text-right">
              <v-btn
                  rounded
                  color="success"
                  dark
                  @click="createCSV"
                  style="margin-left:10px"
              >
                <v-icon left>mdi-download</v-icon>
                CSV
              </v-btn>
            </v-col>
          </v-row>
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("User") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ user.firstName + " " + user.lastName + " (" + user.email + ")" }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('Session duration') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                {{ getFormattedTime(this.user.sessionDurationTotal) }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('Session duration')+" ("+$t("average")+")" }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                {{ getFormattedTime(this.user.sessionDurationAverage) }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('timeout logouts')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                {{ this.user.timeoutLogouts }}
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-row class="s3m-padding">
            <v-col>
              <h3>{{ $t("Licenses") }}</h3>
              <v-data-table :headers="headers" :items="this.user.licenses" class="elevation-1">
                <template v-slot:item.id="{ item }">
                  {{ item.id }}
                </template>
                <template v-slot:item.owner="{ item }">
                  {{ item.owner.firstName+" "+item.owner.lastName }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="s3m-padding">
            <v-col>
              <h3>{{ $t("courses") }}</h3>
              <v-data-table :headers="headers_2" :items="this.user.courses" :sort-by="['sessionDuration']" :sort-desc="[true]" class="elevation-1">
                <template v-slot:item.id="{ item }">
                  {{ item.id }}
                </template>
                <template v-slot:item.sessionDuration="{ item }">
                  {{ getFormattedTime(item.sessionDuration) }}
                </template>
                <template v-slot:item.lastVisited="{ item }">
                  {{ formatLocaleDate(item.lastVisited) }}
                </template>
                <template v-slot:item.sessions="{ item }">
                  {{ item.sessions && item.sessions.length && item.sessions[0].duration ? getFormattedTime(item.sessions[0].duration) : '' }}
                </template>
                <template v-slot:item.completionDate="{ item }">
                  {{ formatLocaleDate(item.completionDate) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                color="primary"
                text
                @click="close"
            >
              {{ $t('Close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
      </v-dialog>
    </v-row>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {
  calculateLoginSessionDuration,
  calculateSessionDuration,
  calcUniqueCourseEvents,
  formatLocaleDate,
  getFormattedTime,
  groupedCourseEvents,
  groupedStudyProgress
} from "@/utils/common";
import APIService from "@/services/APIService";
import Papa from 'papaparse'
// eslint-disable-next-line no-unused-vars

export default {
  name: 'UserStatsDialog',
  data() {
    return {
      courseEvents: null,
      studyProgress: null,
      uniqueCourseEvents: null,
      showLoader: false,
      user: null,
      showDialog: false,
      headers: [
        { text: this.$t('id'), value: 'id' },
        { text: this.$t('title'), value: 'product.name' },
        { text: this.$t('Owner'), value: 'owner' },
      ],
      headers_2: [
        { text: this.$t('id'), value: 'id' },
        { text: this.$t('title'), value: 'title' },
        { text: this.$t('Session duration')+" ("+this.$t('average')+")", value: 'sessionDuration' },
        { text: this.$t('last visited'), value: 'lastVisited' },
        { text: this.$t('last session duration'), value: 'sessions' },
        { text: this.$t('Completed'), value: 'completionDate'}
      ],
    }
  },
  methods: {
    formatLocaleDate,
    getFormattedTime,
    createCSV(){
      let csvData = [];
      csvData.push([]);

      for(let i=0; i < this.headers_2.length; i++){
        csvData[0].push(this.headers_2[i].text);
      }

      const sortedCourses = this.user.courses.sort((a, b) => {
        let aDuration = a.sessions && a.sessions.length && a.sessions[0].duration ? a.sessions[0].duration : 0;
        let bDuration = b.sessions && b.sessions.length && b.sessions[0].duration ? b.sessions[0].duration : 0;
        return bDuration - aDuration;
      });

      for(let i=0; i < sortedCourses.length; i++){
        let current = sortedCourses[i];
        let row = [current.id, current.title, this.getFormattedTime(current.sessionDuration), this.formatLocaleDate(current.lastVisited), current.sessions && current.sessions.length && current.sessions[0].duration ? this.getFormattedTime(current.sessions[0].duration) : '0', this.formatLocaleDate(current.completionDate)];
        csvData.push(row);
      }

      let csv = Papa.unparse(csvData);

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = `${this.user?.firstName ?? 'unknown'}_${this.user?.lastName ?? 'unknown'}_${this.user?.email ?? 'unknown'}.csv`;
      anchor.click();
    },
    async calculateMetrics() {
      console.log(this.groupedCourseEventsData);
      const courseIds = new Set();
      for (let userId in this.groupedCourseEventsData) {
        const userCourseEvents = this.groupedCourseEventsData[userId];
        userCourseEvents.forEach(event => {
          courseIds.add(event.courseId);
        });
      }
      let filteredCourses = this.user.courses.filter(course => courseIds.has(course.id));


      /*console.log(this.user.courses.length);
      let resultObject = await APIServiceStats.calculateMetrics(this.user.id, new Date(this.user.actualFromDate), new Date(this.user.actualToDate), 'Course', filteredCourses, this.groupedCourseEventsData);
      console.log(resultObject);
      filteredCourses = resultObject;*/

      // Erstellen Sie ein Set der IDs der aktualisierten Kurse
      const updatedCourseIds = new Set(filteredCourses.map(course => course.id));

      // Erstellen Sie ein neues Array, das die ursprünglichen Kurse enthält, die nicht geändert wurden
      let newCourses = this.user.courses.filter(course => !updatedCourseIds.has(course.id));

      // Fügen Sie die aktualisierten Kurse dem neuen Array hinzu
      newCourses = newCourses.concat(filteredCourses);

      // Ersetzen Sie this.user.courses mit dem neuen Array
      this.user.courses = newCourses;
      const filteredCourseIds = new Set(filteredCourses.map(course => course.id));

      // Find all the courses in this.user.courses that are NOT in filteredCourses
      let unaffectedCourses = this.user.courses.filter(course => !filteredCourseIds.has(course.id));

      // Combine unaffectedCourses and filteredCourses
      this.user.courses = [...unaffectedCourses, ...filteredCourses.map(course => {
        course.completionDate = "";
        calculateSessionDuration('Course', course.id, course, this.groupedCourseEventsData);

        for (let courseEvent of this.courseEvents) {
          // Wenn die courseId des Events mit der aktuellen Kurs-ID übereinstimmt
          if (courseEvent.courseId === course.id) {
            // Weisen Sie dem Kurs das Datum des Events zu
            course.lastVisited = courseEvent.date;
            break;
          }
        }

        if (Array.isArray(this.studyProgress)) {
          for (let progress of this.studyProgress) {
            // Wenn die courseId und completionDate vorhanden sind, setzen Sie completionDate
            if (progress.course === course.id && progress.completionDate) {
              course.completionDate = progress.completionDate;
              break;
            }
          }
        }

        return course;
      })];
      calculateLoginSessionDuration(this.user, this.groupedCourseEventsData);
      console.log(this.user);

      this.user.timeoutLogouts = this.courseEvents.filter(event => event.type === 'tologout').length;
    },
    show(user) {
      this.user = user ? {...user} : {}
      this.showDialog = true
      this.fetchData();
    },
    async fetchData(){
      this.showLoader = true;

      const [courseEvents, studyProgress, licenses, courses] = await Promise.all([
        APIService.getEvents('', this.user.id,'','', '', '', new Date(this.user.actualFromDate), new Date(this.user.actualToDate)),
        APIService.getStudyProgressUser(this.user.id, new Date(this.user.actualFromDate), new Date(this.user.actualToDate)),
        APIService.getUserLicences(this.user.id),
        APIService.getUserCourses(this.user.id)
      ]);

      this.courseEvents = courseEvents;
      this.studyProgress = studyProgress;
      this.user.licenses = licenses;
      this.user.courses = courses;

      this.uniqueCourseEvents = calcUniqueCourseEvents(this.courseEvents);
      this.calculateMetrics();
      this.user = { ...this.user };
      this.showLoader = false;
    },

    close() {
      this.showDialog = false
    }
  },
  computed: {
    groupedCourseEventsData(){
      return groupedCourseEvents(this.courseEvents);
    },
    groupedStudyProgressData(){
      return groupedStudyProgress(this.studyProgress);
    }
  },
}
</script>

<style scoped>
</style>

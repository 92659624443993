import { render, staticRenderFns } from "./UserStatsDialog.vue?vue&type=template&id=575ce220&scoped=true&"
import script from "./UserStatsDialog.vue?vue&type=script&lang=js&"
export * from "./UserStatsDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "575ce220",
  null
  
)

export default component.exports
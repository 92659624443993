<template>
    <v-row justify="center">
      <v-dialog
          v-model="showDialog"
          persistent
          max-width="1000px"
      >
        <v-card v-if="this.license">
          <v-card-title class="headline">
            {{ $t('Statistics')+": "+ (this.license ? this.license.id : "") }}
          </v-card-title>
          <v-row class="s3m-padding">
            <v-col>
              {{ this.formatLocaleDate(this.license.actualFromDate) + " - " + this.formatLocaleDate(this.license.actualToDate) }}<br>
              {{ $t('text-assing-stats-date-future') }}
            </v-col>
          </v-row>
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("Owner") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ license.owner.firstName + " " + license.owner.lastName + " (" + license.owner.email + ")" }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('Assigned') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ (license.quantityTotal - license.quantityRemaining) }} / {{ license.quantityTotal }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("assignments-total") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ license.assignmentsTotal }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("Assignments")+" 3 "+$t("Days") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ license.assignmentsDays ? license.assignmentsDays.slice(0, 3).reduce((a, b) => a + b, 0)  : 0}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("Assignments")+" 1 "+$t("Week") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ license.assignmentsDays ? license.assignmentsDays.slice(0, 7).reduce((a, b) => a + b, 0)  : 0}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("Assignments")+" 2 "+$t("Weeks") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ license.assignmentsDays ? license.assignmentsDays.slice(0, 14).reduce((a, b) => a + b, 0) : 0 }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("Assignments")+" 4 "+$t("Weeks") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ license.assignmentsDays ? license.assignmentsDays.slice(0, 28).reduce((a, b) => a + b, 0) : 0 }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("last-assignment") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ license.lastAssignment | formatLocaleDate }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("Created on") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ license.createdAt | formatLocaleDate }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-row class="s3m-padding">
            <v-col>
              <h3>{{ $t("Managed") }}</h3>
              <v-data-table :headers="headers" :items="this.license.managers" class="elevation-1">
                <template v-slot:item.email="{ item }">
                  {{ item.email }}
                </template>
                <template v-slot:item.firstMame="{ item }">
                  {{ item.firstname }}
                </template>
                <template v-slot:item.lastName="{ item }">
                  {{ item.lastname }}
                </template>
                <template v-slot:item.lastLogin="{ item }">
                  {{ formatLocaleDate(item.lastLogin) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="s3m-padding">
            <v-col>
              <h3>{{ $t("Assigned") }}</h3>
              <v-data-table
                  :headers="headers"
                  :items="this.license.assignedUsers"
                  class="elevation-1"
                  @click:row="openUserStats"
              >
                <template v-slot:item.email="{ item }">
                  {{ item.email }}
                </template>
                <template v-slot:item.firstName="{ item }">
                  {{ item.firstname }}
                </template>
                <template v-slot:item.lastName="{ item }">
                  {{ item.lastname }}
                </template>
                <template v-slot:item.lastLogin="{ item }">
                  {{ formatLocaleDate(item.lastLogin) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                color="primary"
                text
                @click="close"
            >
              {{ $t('Close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <user-stats-dialog ref="userStatsDialog"></user-stats-dialog>
        <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
      </v-dialog>
    </v-row>
</template>

<script>
import APIService from "@/services/APIService";
import {formatLocaleDate} from "../utils/common";
import {differenceInCalendarDays, parseISO} from 'date-fns';
import UserStatsDialog from "@/components/UserStatsDialog.vue";

export default {
  name: 'LicenseStatsDialog',
  components: {UserStatsDialog},
  data() {
    return {
      showLoader: false,
      license: null,
      showDialog: false,
      licenseAddEvents: null,
      licenseRemoveEvents: null,
      studyProgress: null,
      headers: [
        { text: this.$t('email'), value: 'email' },
        { text: this.$t('firstName'), value: 'firstName' },
        { text: this.$t('lastName'), value: 'lastName' },
        //{ text: this.$t('last-login'), value: 'lastLogin' },
      ],
    }
  },
  methods: {
    formatLocaleDate,
    openUserStats(user) {

      user.actualFromDate = this.license.actualFromDate;
      user.actualToDate = this.license.actualToDate;

      this.$refs.userStatsDialog.show(user);
    },
    calculateCounts() {

      let today = new Date();
      let minDate = this.licenseAddEvents.reduce((min, event) => {
        let eventDate = parseISO(event.date);
        return eventDate < min ? eventDate : min;
      }, today);
      let numDays = differenceInCalendarDays(today, minDate) + 1;
      this.license.assignmentsDays = Array(numDays).fill(0);

      for (let event of this.licenseAddEvents) {
        let eventDate = parseISO(event.date);
        let index = differenceInCalendarDays(today, eventDate);

        // If the event date is not after today and the index is within array bounds
        if (index >= 0 && index < numDays) {
          this.license.assignmentsDays[index]++;
        }
      }

      if (this.licenseAddEvents.length > 0) {
        this.license.lastAssignment = this.licenseAddEvents[this.licenseAddEvents.length - 1].date;
        this.license.assignmentsTotal = this.licenseAddEvents.length;
      } else {
        this.license.lastAssignment = "";
        this.license.assignmentsTotal = "";
      }
    },
    calculateMetrics() {

    },
    show(license) {
      this.license = license ? {...license} : {}
      this.showDialog = true
      this.fetchData();
    },
    async fetchData(){
      this.showLoader = true;
      const licenseAddEventsPromise = APIService.getEvents('addLicenseAssignment', '', '','', '', '', new Date(this.license.actualFromDate), new Date(this.license.actualToDate),  this.license.id);
      const licenseRemoveEventsPromise = APIService.getEvents('removeLicenseAssignment', '', '','', '', '', new Date(this.license.actualFromDate), new Date(this.license.actualToDate),  this.license.id);
      const assignmentsPromise = APIService.getAssignments(this.license.id)
      try {
        const [licenseAddEvents, licenseRemoveEvents, assignments] = await Promise.all([licenseAddEventsPromise, licenseRemoveEventsPromise, assignmentsPromise]);

        this.licenseAddEvents = licenseAddEvents.filter(event => event.licenceId === this.license.id);
        this.licenseRemoveEvents = licenseRemoveEvents.filter(event => event.licenceId === this.license.id);
        this.license.assignmedUserIds = assignments;

        if (this.licenseAddEvents.length > 0) {
          this.license.lastAssignment = this.licenseAddEvents[this.licenseAddEvents.length - 1].data;
        } else {
          this.license.lastAssignment = '';
        }
      } catch(error) {
        console.error(error);
      }
      this.license.assignedUsers = await Promise.all(
          this.license.assignmedUserIds.map(aid => APIService.getUser(aid))
      );

      this.license.managers = await Promise.all(
          this.license.owner.managers.map(mid => APIService.getUser(mid))
      );

      this.calculateCounts();
      this.calculateMetrics();

      this.license = { ...this.license };
      this.showLoader = false;
    },
    async getLogins(users, maxConcurrent) {
      let loginPromises = [];

      for (let i = 0; i < users.length; i += maxConcurrent) {
        let usersChunk = users.slice(i, i + maxConcurrent);
        let promiseChunk = usersChunk.map(user => {
          return APIService.getEvents(
              "LoginEvent",
              user.id,
              "", "", "", "",
              new Date(this.license.actualFromDate),
              new Date(this.license.actualToDate),
              ""
          );
        });

        let responseChunk = await Promise.all(promiseChunk);

        // Extrahieren Sie den "date" Parameter aus jeder Antwort und speichern Sie diesen als "lastLogin" in dem entsprechenden Benutzerobjekt
        for(let j = 0; j < responseChunk.length; j++){
          if (responseChunk[j] && responseChunk[j][0]) {
            usersChunk[j].lastLogin = responseChunk[j][0].date;
          } else {
            //console.warn(`Element at index ${j} or its first element is undefined`, responseChunk[j]);
            usersChunk[j].lastLogin = null;
          }
        }

        loginPromises = loginPromises.concat(responseChunk);
      }

      return loginPromises; // Enthält jetzt alle Login-Ereignisse, und jeder Nutzer hat seine `lastLogin` Eigenschaft gesetzt
    },
    close() {
      this.showDialog = false
    }
  },
  computed: {
  },
}
</script>

<style scoped>
</style>
